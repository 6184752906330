<template>
  <section class="search-box">
    <el-form
      size="small"
      :inline="true"
      :rules="rules"
      ref="form"
      :model="form"
    >
      <el-form-item label="期間を指定" prop="date">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          range-separator="〜"
          start-placeholder="Start"
          end-placeholder="End"
          value-format="timestamp"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          icon="el-icon-search"
          circle
          size="mini"
          :loading="loading"
        ></el-button>
      </el-form-item>
    </el-form>

    <el-alert type="error" show-icon v-if="error">{{
      this.error.message
    }}</el-alert>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    /**
     * IG Insightsの制限で一度のリクエストで30日分しか抽出できない、
     */
    const dateRangeValidator = (rule, value, callback) => {
      const threshold = 2592000 * 1000;
      if (value[1] - value[0] > threshold) {
        callback(new Error('30日を超えてリクエストできません'));
      }
      callback();
    };
    return {
      loading: false,
      error: null,
      form: {
        date: null
      },
      rules: {
        date: [{ required: true }, { validator: dateRangeValidator }]
      }
    };
  },
  computed: {
    period() {
      if (!this.form.date) {
        return null;
      }
      return [
        this.form.date[0] ? this.form.date[0] / 1000 : null,
        this.form.date[1] ? this.form.date[1] / 1000 : null
      ];
    },
    igId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions(['fetchIgUserInsights']),
    onSubmit() {
      this.$refs.form.validate((valid) => {
        const id = this.$route.params.id;
        if (valid) {
          this.fetchIgUserInsights({ id, between: this.period });
        }
      });
    }
  }
};
</script>

<style scoped>
.line {
  text-align: center;
}
</style>
