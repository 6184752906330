var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('el-card',{staticStyle:{"margin-bottom":"10px"}},[_c('account-summary'),_c('el-menu',{attrs:{"router":true}},[_c('el-menu-item',{attrs:{"index":"0","route":{
          name: 'page',
          params: { id: _vm.$route.params.id, pid: _vm.$route.params.pid }
        }}},[_vm._v(" アカウントとメディア ")]),_c('el-menu-item',{attrs:{"index":"2","route":{
          name: 'stories',
          params: { id: _vm.$route.params.id, pid: _vm.$route.params.pid }
        }}},[_vm._v(" ストーリーズ （工事中） ")]),_c('el-menu-item',{attrs:{"index":"1","route":{
          name: 'audience',
          params: { id: _vm.$route.params.id, pid: _vm.$route.params.pid }
        }}},[_vm._v(" オーディエンス ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }