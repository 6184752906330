const axios = require('axios');

const endpoint = process.env.VUE_APP_TRANSLATE_API;

module.exports = class CityTranslateFilter {
  constructor(insight) {
    this.insight = insight;
    this.value = this.insight.values[0].value;
    this.cities = Object.keys(this.value);
  }

  async filter() {
    const cities = await this.fetch();

    this.insight.originalValue = this.value;
    const filtered = {};
    cities.forEach((v, i) => {
      filtered[v] = this.value[this.cities[i]];
    });
    this.insight.values[0].value = filtered;

    return this.insight;
  }

  async fetch() {
    const text = this.cities.join('\n');
    const params = {
      text
    };

    const citiesJa = await axios.get(endpoint, { params });
    return citiesJa.data.split('\n');
  }
};
