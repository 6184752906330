<template>
  <div class="home">
    <fb-login v-if="!account"></fb-login>
    <fb-pages v-if="account"></fb-pages>
  </div>
</template>

<script>
// @ is an alias to /src
import FbLogin from '@/components/FbLogin';
import FbPages from '@/components/FbPages';
import { mapGetters } from 'vuex';

export default {
  name: 'home',
  components: {
    FbLogin,
    FbPages
  },
  computed: {
    ...mapGetters(['account'])
  }
};
</script>
