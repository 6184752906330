<template>
  <nav>
    <el-menu
      mode="horizontal"
      background-color="#333"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item class="no-link">
        <div class="logo-container">
          <img
            class="logo-horizon"
            src="../assets/logo/logo_horizon.svg"
            alt=""
          />
        </div>
      </el-menu-item>
      <template v-if="account">
        <el-menu-item>
          <router-link :to="{ name: 'home' }">アカウントリスト</router-link>
        </el-menu-item>
      </template>
    </el-menu>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['account'])
  }
};
</script>

<style scoped>
.no-link {
  pointer-events: none;
}

.logo-container {
  box-sizing: border-box;
  height: 60px;
}

.logo-horizon {
  height: 40px;
}
</style>
