import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import Page from '../views/Page';
import Comments from '../views/MediaComments';
import Audience from '../views/Audience';
import Stories from '../views/Stories';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/p/:pid/i/:id',
    name: 'page',
    component: Page
  },
  {
    path: '/c/:mediaId',
    name: 'comments',
    component: Comments
  },
  {
    path: '/p/:pid/a/:id',
    name: 'audience',
    component: Audience
  },
  {
    path: '/p/:pid/s/:id',
    name: 'stories',
    component: Stories
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
