<template>
  <div class="buttons">
    <el-button size="mini" icon="el-icon-download" @click="csv">CSV</el-button>
    <el-button size="mini" icon="el-icon-download" @click="json"
      >JSON</el-button
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { unparse } from 'papaparse';

export default {
  methods: {
    csv() {
      this.accountInsights.map((i) => {
        const fileName = `${this.$route.params.id}-${i.name}.csv`;
        const csv = unparse(i.values);

        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = url;
        anchor.click();
      });
    },
    json() {
      const fileName = `account-insights-${this.$route.params.id}.json`;
      const json = JSON.stringify(this.accountInsights, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    }
  },
  computed: {
    ...mapGetters(['accountInsights'])
  }
};
</script>

<style></style>
