const moment = require('moment');
const twitter = require('twitter-text').default;

module.exports = class IGMedia {
  constructor(media) {
    this.media = media;
  }

  static castMedia(media) {
    const proxy = new Proxy(media, this.proxyHandlers());
    return proxy;
  }

  static proxyHandlers() {
    return {
      get: (target, property) => {
        if (property in target || typeof property === 'symbol') {
          return target[property];
        }

        if (property === 'jst') {
          return moment(target.timestamp).format('YYYY-MM-DD HH:mm:ss');
        }

        if (property === 'hashtags') {
          const hashtags = this.extractHashtags(target.caption || '');
          return hashtags.join(', ');
        }

        if (property === 'date') {
          return moment(target.timestamp).format('YYYY-MM-DD(ddd)');
        }

        if (property === 'time') {
          return moment(target.timestamp).format('HH:mm:ss');
        }

        return this.extractInsight(property, target.insights);
      }
    };
  }

  static extractHashtags(caption) {
    const preparedCaption = caption.replace(/＃/g, '#').replace(/#/g, ' #');
    const hashtags = twitter.extractHashtags(preparedCaption);
    return hashtags;
  }

  static insightsParams(type) {
    let metric;
    if (type === 'IMAGE') {
      metric = 'engagement,impressions,reach,saved';
    }
    if (type === 'VIDEO') {
      metric = 'engagement,impressions,reach,saved,video_views';
    }
    if (type === 'CAROUSEL_ALBUM') {
      metric =
        'carousel_album_engagement,carousel_album_impressions,carousel_album_reach,carousel_album_saved';
    }
    return { metric };
  }

  static extractInsight(key, insights) {
    if (!insights) {
      return undefined;
    }

    const insight = insights.find((i) => {
      return i.name.includes(key);
    });

    if (insight) {
      return insight.values[0].value;
    }

    return undefined;
  }
};
