<template>
  <div class="buttons">
    <el-button size="mini" icon="el-icon-download" @click="csv">CSV</el-button>
    <el-button size="mini" icon="el-icon-download" @click="json"
      >JSON</el-button
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { unparse } from 'papaparse';
export default {
  computed: {
    ...mapGetters(['audiences', 'instagramAccount'])
  },
  methods: {
    csv() {
      const account = this.instagramAccount.username;
      this.audiences.map((i) => {
        const fileName = `${account}-${i.name}.csv`;
        const data = Object.keys(i.values[0].value).map((k) => {
          return {
            key: k,
            value: i.values[0].value[k]
          };
        });

        const csv = unparse(data);
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = url;
        anchor.click();
      });
    },
    json() {
      const account = this.instagramAccount.username;
      const fileName = `${account}-audiences.json`;
      const json = JSON.stringify(this.audiences, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = url;
      anchor.click();
    }
  }
};
</script>

<style scoped>
.buttons {
  padding-bottom: 12px;
}
</style>
