<template>
  <el-card class="insight-card" shadow="never">
    <header slot="header" class="clearfix">
      {{ insight.title }}
      <el-tooltip :content="insight.description" placement="top">
        <el-button icon="el-icon-question" type="text"></el-button>
      </el-tooltip>
    </header>
    <div class="values" v-for="v in insight.values" :key="v.end_time">
      {{ toJst(v.end_time) }}: {{ v.value }}件
    </div>
  </el-card>
</template>

<script>
import moment from 'moment';
export default {
  props: ['insight'],
  methods: {
    toJst(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style lang="scss">
.insight-card {
  margin-bottom: 10px;

  .el-card__header {
    padding: 9px 18px;
  }
}
.values {
  font-family: monospace;
}
</style>
