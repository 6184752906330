<template>
  <div class="accounts-container" v-loading="loading">
    <h2 style="text-align: center">Accounts</h2>

    <account-list-item
      v-for="page in pages"
      :key="page.id"
      :page="page"
    ></account-list-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccountListItem from './AccountListItem';
import contractedPagesProvider from '../modules/fbpages/contracted-pages-provider';

const basicParams = {
  limit: 50,
  fields: 'picture{url},name,instagram_business_account,id'
};

export default {
  data() {
    return { pages: [], loading: true };
  },
  components: {
    AccountListItem
  },
  computed: {
    ...mapGetters(['account'])
  },
  methods: {
    fetchPages(paging) {
      this.loading = true;
      const FB = window.FB;
      const params = Object.assign({}, basicParams, paging || {});
      FB.api('/me/accounts', 'get', params, (res) => {
        const contracterPages = contractedPagesProvider();
        // 実行環境が `biz`のときFBページIDでフィルタする
        const pages = res.data.filter((p) => {
          if (process.env.VUE_APP_ENV == 'biz') {
            // filter
            return contracterPages.includes(p.id);
          }
          return p;
        });

        pages.forEach((v) => {
          if (v.instagram_business_account) {
            this.fetchIgAccount(v);
          }
        });
        if (res.paging.next) {
          this.fetchPages({ after: res.paging.cursors.after });
        }
        this.loading = false;
      });
    },
    fetchIgAccount(fbAccount) {
      const igId = fbAccount.instagram_business_account.id;
      const FB = window.FB;
      FB.api(
        `/${igId}`,
        'get',
        {
          fields:
            'name,username,profile_picture_url,followers_count,media_count'
        },
        (res) => {
          fbAccount.instagram = res;
          this.pages.push(fbAccount);
          this.pages.sort((a, b) => {
            if (a.instagram.username < b.instagram.username) {
              return -1;
            }
            return 1;
          });
        }
      );
    }
  },
  mounted() {
    this.fetchPages();
  }
};
</script>

<style scoped>
.accounts-container {
  width: 70%;
  margin: 0 auto;
}
</style>
