<template>
  <div id="app">
    <nav-menu></nav-menu>
    <div class="content" v-if="fbSdkLoaded">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavMenu from '@/components/NavMenu';
import { mapGetters } from 'vuex';

const appId = process.env.VUE_APP_FB_APP_ID;
export default {
  data() {
    return {
      fbSdkLoaded: false
    };
  },
  components: {
    NavMenu
  },
  computed: {
    ...mapGetters(['account'])
  },
  mounted() {
    this.fbInit();
  },
  methods: {
    loadSdk(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/ja_JP/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    },
    fbInit() {
      const store = this.$store;
      window.fbAsyncInit = () => {
        const FB = window.FB;
        FB.init({
          appId: appId,
          cookie: true,
          xfbml: true,
          version: 'v13.0'
        });

        FB.AppEvents.logPageView();

        FB.getLoginStatus((res) => {
          this.fbSdkLoaded = true;
          if (res.status === 'connected') {
            store.commit('account', { account: res.authResponse });
          }
        });
      };

      this.loadSdk(document, 'script', 'facebook-jssdk');
    }
  }
};
</script>

<style lang="scss">
body {
  background-color: #fafafa;
  margin: 0;
}
.content {
  padding: 20px 8px;
}
</style>
