<template>
  <div>
    <section class="media" v-if="media">
      <router-link :to="{ name: 'page', params: { id: media.owner.id } }">
        アカウント情報へ
      </router-link>
      <el-card>
        <a :href="media.permalink">{{ media.permalink }}</a>
        <el-row :gutter="20">
          <el-col :span="6">
            <a :href="media.permalink" target="_blank">
              <el-image
                v-if="media.media_type === 'VIDEO'"
                :src="media.thumbnail_url"
              ></el-image>
              <el-image v-else :src="media.media_url"></el-image>
            </a>

            <div class="stats">
              <ul>
                <li>いいね! {{ media.like_count }}</li>
                <li>コメント {{ media.comments_count }}</li>
              </ul>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="caption">{{ media.caption }}</div>
          </el-col>
        </el-row>
      </el-card>
    </section>

    <el-card class="comments">
      <header>
        <h3>コメント</h3>
        <el-button
          size="mini"
          icon="el-icon-download"
          @click="csv"
          v-if="comments.length"
          >CSV</el-button
        >
        <el-button
          size="mini"
          icon="el-icon-download"
          @click="json"
          v-if="comments.length"
          >JSON</el-button
        >
        <div>
          <small>{{ comments.length }}件取得できました</small>
        </div>
      </header>
      <div class="loading" v-if="loading">
        <el-button :loading="loading" type="text">コメント取得中...</el-button>
      </div>
      <article class="comment" v-for="c in comments" :key="c.id">
        <el-card>
          <b>@{{ c.username }}</b>
          <div class="text">{{ c.text }}</div>

          <div class="ts">
            <el-tooltip content="リプあり" placement="top" v-if="c.replies">
              <i class="el-icon-chat-dot-round"></i>
            </el-tooltip>

            <small>
              (
              <time>{{ c.timestamp }}</time>
              )
            </small>
          </div>
        </el-card>
      </article>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { unparse } from 'papaparse';

export default {
  data() {
    return {
      loading: true,
      media: null,
      comments: []
    };
  },
  computed: {
    ...mapGetters(['account'])
  },
  methods: {
    jst(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    fetchMedia() {
      const fb = window.FB;
      const id = this.$route.params.mediaId;
      fb.api(
        `/${id}`,
        'get',
        {
          fields: [
            'caption',
            'media_url',
            'media_type',
            'permalink',
            'thumbnail_url',
            'comments_count',
            'like_count',
            'id',
            'timestamp',
            'owner'
          ].join(',')
        },
        (res) => {
          this.media = res;
        }
      );
    },
    fetchComments(paging) {
      const fb = window.FB;
      const id = this.$route.params.mediaId;
      const basicParams = {
        fields: [
          'id',
          'user',
          'username',
          'text',
          'like_count',
          'timestamp',
          'hidden',
          'replies{id,text,username}'
        ].join(','),
        limit: 50 // API仕様上の上限
      };

      this.loading = true;

      const params = Object.assign({}, basicParams, paging || {});
      fb.api(`/${id}/comments`, 'get', params, (res) => {
        res.data.map((comment) => {
          const ts = this.jst(comment.timestamp);
          comment.timestamp = ts;
          this.comments.push(comment);
        });
        if (res.paging && res.paging.next) {
          this.fetchComments({ after: res.paging.cursors.after });
        }
      });

      this.loading = false;
    },
    csv() {
      const csv = unparse(this.comments);
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const latest = this.comments[0];

      const filename = `comments-${this.$route.params.id}-${latest.timestamp}.csv`;
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    },
    json() {
      const json = JSON.stringify(this.comments, null, 2);

      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const latest = this.comments[0];

      const filename = `comments-${this.$route.params.id}-${latest.timestamp}.json`;
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    }
  },
  watch: {
    account(value) {
      if (value) {
        this.fetchMedia();
        this.fetchComments();
      }
    }
  },
  mounted() {
    if (this.account) {
      this.fetchMedia();
      this.fetchComments();
    }
  }
};
</script>

<style scoped>
.comment {
  padding: 2px;
}
.text,
.caption {
  white-space: pre-wrap;
}

.caption {
  font-size: smaller;
}

.ts {
  text-align: right;
}
.media {
  margin-bottom: 20px;
}
</style>
