<template>
  <section v-if="medias">
    <header>
      <h2>メディア</h2>
      <div class="buttons" v-if="medias && medias.length">
        <el-button size="mini" icon="el-icon-download" @click="csv">
          CSV
        </el-button>
        <el-button size="mini" icon="el-icon-download" @click="json">
          JSON
        </el-button>
      </div>
      <p>50件づつ表示します</p>
    </header>
    <section class="medias">
      <el-table
        :data="medias"
        :default-sort="{ prop: 'jst', order: 'descending' }"
        border
      >
        <el-table-column label="media" width="124" fixed>
          <template slot-scope="scope">
            <a :href="scope.row.permalink" target="_blank">
              <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.thumbnail_url || scope.row.media_url"
                fit="contain"
              ></el-image>
            </a>
            <small>(id: {{ scope.row.id }})</small>
          </template>
        </el-table-column>
        <el-table-column prop="jst">
          <template slot="header">
            <i class="el-icon-time"></i>
          </template>
        </el-table-column>
        <el-table-column label="type" prop="media_type" width="60">
          <template slot-scope="scope">
            <i
              class="el-icon-camera"
              v-if="scope.row.media_type == 'IMAGE'"
            ></i>
            <i
              class="el-icon-video-camera"
              v-if="scope.row.media_type == 'VIDEO'"
            ></i>
            <i
              class="el-icon-files"
              v-if="scope.row.media_type == 'CAROUSEL_ALBUM'"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          label="product"
          prop="media_product_type"
          width="60"
        ></el-table-column>

        <el-table-column label="caption" prop="caption"></el-table-column>
        <el-table-column label="hashtags" prop="hashtags"></el-table-column>
        <el-table-column label="likes" prop="like_count" width="50">
          <template slot="header">
            <el-tooltip content="いいね" placement="top">
              <i class="el-icon-star-off"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="comments" prop="comments_count" width="50">
          <template slot="header">
            <el-tooltip content="コメント" placement="top">
              <i class="el-icon-chat-square"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'comments',
                params: {
                  mediaId: scope.row.id,
                  id: igId
                }
              }"
              v-if="scope.row.comments_count"
            >
              <b>{{ scope.row.comments_count }}</b>
            </router-link>
            <span v-else>{{ scope.row.comments_count }}</span>
          </template>
        </el-table-column>

        <el-table-column label="engagement" prop="engagement" width="60">
          <template slot="header">
            <el-tooltip content="エンゲージメント" placement="top">
              <i class="el-icon-s-opportunity"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="impressions" prop="impressions" width="50">
          <template slot="header">
            <el-tooltip content="インプレッション" placement="top">
              <i class="el-icon-view" type="text"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="reach" prop="reach" width="50">
          <template slot="header">
            <el-tooltip content="リーチ" placement="top">
              <i class="el-icon-user" title="リーチ"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="saved" prop="saved" width="50">
          <template slot="header">
            <el-tooltip content="保存" placement="top">
              <i class="el-icon-collection" title="保存"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <el-button-group style="padding-top: 20px; margin: 0 auto" v-if="paging">
        <el-button
          size="mini"
          icon="el-icon-arrow-left"
          :disabled="!paging.previous"
          @click="fetchMedias({ before: paging.cursors.before })"
        ></el-button>
        <el-button
          size="mini"
          icon="el-icon-arrow-right"
          :disabled="!paging.next"
          @click="fetchMedias({ after: paging.cursors.after })"
        ></el-button>
      </el-button-group>
    </section>
  </section>
</template>

<script>
import IGMedia from '../modules/IGMedia';
import { mapGetters } from 'vuex';
import { unparse } from 'papaparse';

export default {
  data() {
    return {
      medias: null,
      paging: null
    };
  },
  computed: {
    ...mapGetters(['account']),
    igId() {
      return this.$route.params.id;
    }
  },
  methods: {
    fetchMedias(cursor) {
      const fb = window.FB;
      const fields = [
        'id',
        'media_url',
        'thumbnail_url',
        'caption',
        'media_type',
        'media_product_type',
        'permalink',
        'comments_count',
        'like_count',
        'timestamp'
      ].join(',');

      const pageParam = cursor || {};

      fb.api(
        `/${this.igId}/media`,
        'get',
        {
          fields,
          ...pageParam,
          limit: 50
        },
        (res) => {
          this.medias = [];
          this.paging = res.paging;
          res.data.forEach((m) => {
            const igMedia = IGMedia.castMedia(m);
            const params = IGMedia.insightsParams(igMedia.media_type);
            fb.api(`/${m.id}/insights`, 'get', params, (res) => {
              if (res.data) {
                igMedia.insights = res.data;
              } else {
                igMedia.insights = null;
              }

              this.medias.push(igMedia);
              this.medias.sort((a, b) => {
                if (a.timestamp < b.timestamp) {
                  return 1;
                }
                return -1;
              });
            });
          });
        }
      );
    },
    csv() {
      const csv = unparse(this.mediaDataToCsv());

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const filename = `medias-${this.$route.params.id}.csv`;
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    },
    json() {
      const json = JSON.stringify(this.medias, null, 2);

      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const filename = `medias-${this.$route.params.id}.json`;
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    },
    mediaDataToCsv() {
      const data = this.medias.map((m) => {
        const media = Object.assign({}, m);
        delete media.insights;

        return Object.assign(media, {
          jst: m.jst,
          hashtags: m.hashtags,
          engagement: m.engagement,
          impressions: m.impressions,
          reach: m.reach,
          saved: m.saved
        });
      });
      return data;
    }
  },
  mounted() {
    this.fetchMedias();
  }
};
</script>

<style></style>
