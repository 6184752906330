<template>
  <section>
    <header>
      <h2>オーディエンス</h2>
    </header>
    <el-row :gutter="20">
      <el-col :span="6">
        <side-menu :accountData="instagramAccount"></side-menu>
      </el-col>
      <el-col :span="18">
        <el-card>
          <download-buttons></download-buttons>
          <section class="audience-insights">
            <audience-card
              v-for="a in audiences"
              :key="a.id"
              :metric="a"
            ></audience-card>
          </section>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import AudienceCard from '../components/AudienceCard';
import DownloadButtons from '../components/Audience/DownloadButtons';
import SideMenu from '../components/SideMenu';

import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    AudienceCard,
    DownloadButtons,
    SideMenu
  },
  watch: {
    account(value) {
      if (value) {
        const id = this.$route.params.id;
        this.fetchInstagramAccount(id);
        this.fetchAudience({ id });
      }
    }
  },
  computed: {
    ...mapGetters(['account', 'audiences', 'instagramAccount']),
    igId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions(['fetchAudience', 'fetchInstagramAccount'])
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.commit('init');

    this.fetchInstagramAccount(id);
    this.fetchAudience({ id });
  }
};
</script>
