<template>
  <el-card shadow="never" style="margin-bottom: 12px">
    <header slot="header" class="clearfix">
      {{ metric.title }}
      <el-tooltip :content="metric.description" placement="top">
        <el-button icon="el-icon-question" type="text"></el-button>
      </el-tooltip>
      <time :datetime="metric.values[0].end_time">{{ endTime }}</time>
    </header>
    <div class="values">
      <el-row :gutter="50">
        <el-col :span="10">
          <el-table :data="sortedValue" max-height="600">
            <el-table-column prop="key" width="300"></el-table-column>
            <el-table-column prop="value"></el-table-column>
          </el-table>
        </el-col>
        <el-col :span="14">
          <doughnut-chart
            :chart-id="metric.name"
            :metric="sortedValue"
          ></doughnut-chart>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import moment from 'moment';
import DoughnutChart from './Chart/DoughnutChart';
export default {
  props: ['metric'],
  components: {
    DoughnutChart
  },
  computed: {
    endTime() {
      return moment(this.metric.values[0].end_time).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    },
    sortedValue() {
      const keys = Object.keys(this.metric.values[0].value);
      const value = keys
        .map((i) => {
          return {
            key: i,
            value: this.metric.values[0].value[i]
          };
        })
        .sort((a, b) => {
          if (a.value < b.value) {
            return 1;
          }
          return -1;
        });
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
.values {
  font-family: monospace;
}
</style>
