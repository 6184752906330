const axios = require('axios');

const endpoint = process.env.VUE_APP_COUNTRY_CODE_API;

module.exports = class CountryCodeFilter {
  constructor(insight) {
    this.insight = insight;
    this.value = this.insight.values[0].value;
    this.countryCodes = Object.keys(this.value);
  }

  async filter() {
    const countryNames = await this.fetch();
    const filteredValue = {};
    countryNames.forEach((c) => {
      filteredValue[c[0]] = this.value[c[3]];
    });
    this.insight.values[0].value = filteredValue;
    return this.insight;
  }

  async fetch() {
    const params = {
      code: this.countryCodes.join(',')
    };
    const countryNames = await axios.get(endpoint, { params });
    return countryNames.data;
  }
};
