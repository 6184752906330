<template>
  <section v-if="instagramAccount">
    <el-avatar
      :size="50"
      :src="instagramAccount.profile_picture_url"
    ></el-avatar>

    <span>{{ instagramAccount.name }}</span>
    <a
      :href="`https://www.instagram.com/${instagramAccount.username}`"
      target="_blank"
    >
      @{{ instagramAccount.username }}
    </a>
    <small>(ID: {{ instagramAccount.id }})</small>
    <p>
      <span>フォロワー {{ instagramAccount.followers_count }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>フォロー中 {{ instagramAccount.follows_count }}</span>
      <el-divider direction="vertical"></el-divider>
      <span>メディア数 {{ instagramAccount.media_count }}</span>
    </p>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['account', 'audiences', 'instagramAccount'])
  },
  methods: {
    ...mapActions(['fetchAudience', 'fetchInstagramAccount'])
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.commit('init');

    this.fetchInstagramAccount(id);
    this.fetchAudience({ id });
  }
};
</script>
