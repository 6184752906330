<template>
  <button @click="fbLogin">login</button>
</template>

<script>
export default {
  methods: {
    fbLogin() {
      const FB = window.FB;
      FB.login(
        (res) => {
          if (res.authResponse) {
            this.$store.commit('account', { account: res.authResponse });
          }
        },
        {
          scope:
            'public_profile,instagram_basic,instagram_manage_insights,pages_show_list'
        }
      );
    }
  }
};
</script>

<style></style>
