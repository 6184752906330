<template>
  <el-card style="margin-bottom: 8px">
    <el-row :gutter="10">
      <el-col :span="1">
        <el-image
          style="width: 100%"
          :src="page.instagram.profile_picture_url"
          fit="cover"
        ></el-image>
      </el-col>
      <el-col :span="19">
        <router-link
          :to="{
            name: 'page',
            params: {
              id: page.instagram_business_account.id,
              pid: page.id
            }
          }"
        >
          @{{ page.instagram.username }}
          <span class="name" v-if="page.instagram.name">{{
            page.instagram.name
          }}</span>
        </router-link>

        <span class="count">
          <i class="el-icon-user-solid"></i>
          {{ page.instagram.followers_count }}
        </span>
        <span class="count">
          <i class="el-icon-picture followers"></i>
          {{ page.instagram.media_count }}
        </span>
      </el-col>
      <el-col :span="4">
        <el-tooltip effect="dark" :content="page.name" placement="top-start">
          <a :href="`https://www.facebook.com/${page.id}`" target="_blank">
            <el-image
              style="width: 24px"
              :src="page.picture.data.url"
            ></el-image
            >FBページ
          </a>
        </el-tooltip>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  props: ['page']
};
</script>

<style scoped>
.name {
  display: inline-block;
  margin-left: 0.5em;
}
.count {
  display: inline-block;
  margin-left: 0.5em;
  font-size: smaller;
}
</style>
