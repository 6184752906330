<template>
  <section>
    <header>
      <h2>アカウント</h2>
    </header>

    <div class="controls">
      <search-box></search-box>

      <download-buttons></download-buttons>
    </div>

    <el-row :gutter="10" class="user-insights" v-if="accountInsights">
      <el-col
        :span="8"
        class="user-insight"
        v-for="i in accountInsights"
        :key="i.id"
      >
        <insight-card :insight="i"></insight-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import InsightCard from './InsightCard';
import SearchBox from './AccountInsights/SearchBox';
import DownloadButtons from './AccountInsights/DownloadButtons';

export default {
  props: ['accountData', 'accountInsights'],
  components: {
    InsightCard,
    SearchBox,
    DownloadButtons
  }
};
</script>

<style scoped>
.controls {
  padding-bottom: 10px;
}
</style>
