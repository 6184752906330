<template>
  <section>
    <header>
      <h2>ストーリーズ</h2>
    </header>

    <el-row :gutter="20">
      <el-col :span="6"> <side-menu> </side-menu></el-col>
      <el-col :span="18">
        <el-card>
          <ul class="attention">
            <li>
              リアルタイムの指標です。現状ではストーリーズ公開から24時間経過後は指標を取得できませんので注意してください（今後のバージョンアップで有効期限後も履歴を参照できるようにする予定です）。
            </li>
            <li>
              <a
                href="https://developers.facebook.com/docs/instagram-api/reference/ig-media/insights#--"
                title="インサイト - Instagramプラットフォーム"
                target="_blank"
                rel="noopener noreferrer"
                >Instagram側の仕様</a
              >により、アクセス数が少ない場合は詳細な指標が取れない場合があります。
            </li>
            <li>
              <code>
                replies
                <i class="el-icon-message" title="リプライ"></i>
              </code>
              指標は
              <a
                href="https://developers.facebook.com/docs/instagram-api/reference/ig-media/insights#--"
                title="インサイト - Instagramプラットフォーム"
                target="_blank"
                rel="noopener noreferrer"
              >
                InstagramのAPIの制限</a
              >
              により値が取得できません。
            </li>
          </ul>

          <section class="medias" v-if="medias && medias.length">
            <div
              class="buttons"
              style="margin-bottom: 10px"
              v-if="medias && medias.length"
            >
              <el-button size="mini" icon="el-icon-download" @click="csv">
                CSV
              </el-button>
              <el-button size="mini" icon="el-icon-download" @click="json">
                JSON
              </el-button>
            </div>

            <el-table
              :data="medias"
              :default-sort="{ prop: 'jst', order: 'descending' }"
              border
            >
              <!-- サムネ -->
              <el-table-column label="media" width="124" fixed>
                <template slot-scope="scope">
                  <a :href="scope.row.permalink" target="_blank">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="scope.row.thumbnail_url || scope.row.media_url"
                      fit="contain"
                    ></el-image>
                  </a>
                  <small>(id: {{ scope.row.id }})</small>
                </template>
              </el-table-column>

              <!-- 公開日時JST -->
              <el-table-column prop="jst">
                <template slot="header">
                  <i class="el-icon-time"></i>
                </template>
              </el-table-column>

              <!-- タイプ -->
              <el-table-column label="type" prop="media_type" width="60">
                <template slot-scope="scope">
                  <i
                    class="el-icon-camera"
                    v-if="scope.row.media_type == 'IMAGE'"
                  ></i>
                  <i
                    class="el-icon-video-camera"
                    v-if="scope.row.media_type == 'VIDEO'"
                  ></i>
                </template>
              </el-table-column>

              <!-- プロダクトタイプ -->
              <el-table-column
                label="product"
                prop="media_product_type"
                width="60"
              >
              </el-table-column>

              <!-- キャプション -->
              <el-table-column label="caption" prop="caption"></el-table-column>

              <!-- ハッシュタグ -->
              <el-table-column
                label="hashtags"
                prop="hashtags"
              ></el-table-column>

              <!-- いいね -->
              <el-table-column label="likes" prop="like_count" width="50">
                <template slot="header">
                  <el-tooltip content="いいね" placement="top">
                    <i class="el-icon-star-off"></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <!-- コメント -->
              <el-table-column
                label="comments"
                prop="comments_count"
                width="50"
              >
                <template slot="header">
                  <el-tooltip content="コメント" placement="top">
                    <i class="el-icon-chat-square"></i>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <router-link
                    :to="{
                      name: 'comments',
                      params: {
                        mediaId: scope.row.id,
                        id: igId
                      }
                    }"
                    v-if="scope.row.comments_count"
                  >
                    <b>{{ scope.row.comments_count }}</b>
                  </router-link>
                  <span v-else>{{ scope.row.comments_count }}</span>
                </template>
              </el-table-column>

              <!-- Insightsによる追加の指標 -->
              <!-- impressions -->
              <el-table-column
                label="impressions"
                prop="impressions"
                width="50"
              >
                <template slot="header">
                  <el-tooltip
                    content="インプレッション: ストーリーズIGメディアオブジェクトが閲覧された合計回数。"
                    placement="top"
                  >
                    <i class="el-icon-view" type="text"></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <!-- reach -->
              <el-table-column label="reach" prop="reach" width="50">
                <template slot="header">
                  <el-tooltip
                    content="リーチ: ストーリーズIGメディアオブジェクトを閲覧したユニークInstagramアカウントの合計数。"
                    placement="top"
                  >
                    <i class="el-icon-user" title="リーチ"></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <!-- replies -->
              <el-table-column label="replies" prop="replies" width="50">
                <template slot="header">
                  <el-tooltip
                    content="replies: ストーリーズIGメディアオブジェクトの返信(IGコメント)の合計数。"
                    placement="top"
                  >
                    <i class="el-icon-message" title="リプライ"></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <!-- exits -->
              <el-table-column label="exits" prop="exits" width="50">
                <template slot="header">
                  <el-tooltip
                    content="exits: ユーザーがストーリーズIGメディアオブジェクトを閉じた回数。"
                    placement="top"
                  >
                    <i class="el-icon-close" title="exits"></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <!-- taps_forward -->
              <el-table-column
                label="taps_forward"
                prop="taps_forward"
                width="50"
              >
                <template slot="header">
                  <el-tooltip
                    content="taps_forward: このストーリーズIGメディアオブジェクトの次の写真または動画を閲覧するためにタップされた合計回数。"
                    placement="top"
                  >
                    <i class="el-icon-d-arrow-right" title="taps_forward"></i>
                  </el-tooltip>
                </template>
              </el-table-column>

              <!-- taps_back -->
              <el-table-column label="taps_back" prop="taps_back" width="50">
                <template slot="header">
                  <el-tooltip
                    content="taps_back: このストーリーズIGメディアオブジェクトの前の写真または動画を閲覧するためにタップされた合計回数。"
                    placement="top"
                  >
                    <i class="el-icon-d-arrow-left" title="taps_back"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </section>
        </el-card>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import IGMedia from '../modules/IGMedia';
import SideMenu from '../components/SideMenu';
import { mapActions, mapGetters } from 'vuex';
import { unparse } from 'papaparse';

export default {
  components: {
    SideMenu
  },
  data: () => {
    return { medias: null };
  },
  computed: {
    ...mapGetters(['account', 'stories', 'instagramAccount'])
  },
  watch: {
    stories(val) {
      if (val.data) {
        this.fetchStoriesInsights();
      }
    }
  },
  methods: {
    ...mapActions(['fetchStories', 'fetchInstagramAccount']),
    mediaDataToCsv() {
      const data = this.medias.map((m) => {
        const media = Object.assign({}, m);
        delete media.insights;

        return Object.assign(media, {
          jst: m.jst,
          hashtags: m.hashtags,
          impressions: m.impressions,
          reach: m.reach,
          replies: m.replies,
          exits: m.exits,
          taps_forward: m.taps_forward,
          taps_back: m.taps_back
        });
      });
      return data;
    },
    csv() {
      const csv = unparse(this.mediaDataToCsv());

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const filename = `medias-${this.$route.params.id}.csv`;
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    },
    json() {
      const json = JSON.stringify(this.medias, null, 2);

      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const filename = `medias-${this.$route.params.id}.json`;
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    },
    fetchStoriesInsights() {
      this.medias = [];
      const fb = window.FB;

      /**
       * 注: 値が5未満のストーリーズIGメディア指標は、(#10) Not enough viewers for the media to show insightsというメッセージとともにエラーコード10を返します。
       *
       * @see https://developers.facebook.com/docs/instagram-api/reference/ig-media/insights
       */
      this.stories.data.forEach((item) => {
        const media = IGMedia.castMedia(item);
        const metric = 'exits,impressions,reach,replies,taps_forward,taps_back';
        const id = item.id;
        fb.api(
          `/${id}/insights`,
          'get',
          {
            metric
          },
          (res) => {
            if (res.error) {
              media.insights = null;
            } else {
              media.insights = res.data;
            }
            this.medias.push(media);
            this.medias.sort((a, b) => {
              if (a.timestamp < b.timestamp) {
                return 1;
              }
              return -1;
            });
          }
        );
      });
    }
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.commit('init');

    this.fetchInstagramAccount(id);
    this.fetchStories({ id });
  }
};
</script>
