<template>
  <section>
    <header>
      <h2>アカウントとメディアのインサイト</h2>
    </header>
    <el-row :gutter="20">
      <!-- サイドメニュー -->
      <el-col :span="6">
        <side-menu></side-menu>
      </el-col>

      <el-col :span="18">
        <template class="error" v-if="error">
          <el-card>
            <el-alert
              :title="error.message"
              type="error"
              show-icon
              :closable="false"
            ></el-alert>
            <router-link :to="{ name: 'home' }">戻る</router-link>
          </el-card>
        </template>
        <template v-else>
          <section class="instagram-account">
            <el-card>
              <template v-if="instagramAccount" style="margin-bottom: 20px">
                <account-insights
                  :accountData="instagramAccount"
                  :accountInsights="accountInsights"
                ></account-insights>
              </template>
              <instagram-medias v-if="account"></instagram-medias>
            </el-card>
          </section>
        </template>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AccountInsights from '../components/AccountInsights';
import InstagramMedias from '../components/InstagramMedias';
import SideMenu from '../components/SideMenu';

export default {
  data() {
    return { accountData: null, error: null };
  },
  components: {
    AccountInsights,
    InstagramMedias,
    SideMenu
  },
  methods: {
    ...mapActions(['fetchInstagramAccount', 'fetchIgUserInsights']),
    extractMetric(metric) {
      if (!this.accountInsights) {
        return null;
      }
      return this.accountInsights.find((val) => {
        return val.name === metric;
      });
    }
  },
  computed: {
    ...mapGetters(['account', 'accountInsights', 'instagramAccount']),
    igId() {
      return this.$route.params.id;
    },
    followerCount() {
      return this.extractMetric('follower_count');
    }
  },
  watch: {
    account(value) {
      if (value) {
        const id = this.$route.params.id;
        this.fetchInstagramAccount(id);
        this.fetchIgUserInsights({ id });
      }
    }
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.commit('init');
    this.fetchInstagramAccount(id);
    this.fetchIgUserInsights({ id });
  }
};
</script>
