/**
 * @return array
 */
module.exports = function provider() {
  const pages = process.env.VUE_APP_CONTRACTED_PAGES;
  if (!pages) {
    return [];
  }
  return JSON.parse(pages);
};
