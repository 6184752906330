import Vue from 'vue';
import Vuex from 'vuex';
import audienceDataFilter from '@/modules/filter/audienceDataFilter';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account: null,
    accountInsights: null,
    audiences: null,
    instagramAccount: null,
    stories: null
  },
  mutations: {
    account(state, { account }) {
      state.account = account;
    },
    accountInsights(state, { insights }) {
      state.accountInsights = insights;
    },
    audiences(state, data) {
      state.audiences = data;
    },
    instagramAccount(state, data) {
      state.instagramAccount = data;
    },
    stories(state, data) {
      state.stories = data;
    },
    init(state) {
      state.accountInsights = null;
      state.audiences = null;
      state.instagramAccount = null;
      state.stories = null;
    }
  },
  getters: {
    account(state) {
      return state.account;
    },
    accountInsights(state) {
      return state.accountInsights;
    },
    audiences(state) {
      return state.audiences;
    },
    instagramAccount(state) {
      return state.instagramAccount;
    },
    stories(state) {
      return state.stories;
    }
  },
  actions: {
    fetchAudience(context, { id }) {
      const fb = window.FB;
      fb.api(
        `/${id}/insights`,
        'get',
        {
          metric:
            'audience_city,audience_country,audience_gender_age,audience_locale',
          period: 'lifetime'
        },
        (res) => {
          const promises = audienceDataFilter(res.data);
          promises.then((result) => {
            context.commit('audiences', result);
          });
        }
      );
    },
    fetchInstagramAccount(context, id) {
      const FB = window.FB;
      FB.api(
        `/${id}`,
        'get',
        {
          fields:
            'name,media_count,profile_picture_url,username,followers_count,follows_count,id'
        },
        (res) => {
          context.commit('instagramAccount', res);
        }
      );
    },
    fetchIgUserInsights(context, { id, between }) {
      const FB = window.FB;

      const metric = [
        'follower_count',
        'impressions,reach',
        'profile_views',
        'text_message_clicks',
        'website_clicks',
        'email_contacts',
        'get_directions_clicks',
        'phone_call_clicks'
      ].join(',');

      const params = {
        metric,
        period: 'day'
      };

      if (between) {
        params.since = between[0];
        params.until = between[1];
      }

      FB.api(`/${id}/insights`, 'get', params, (res) => {
        if (res.error) {
          this.error = res.error;
        }
        context.commit('accountInsights', { insights: res.data });
      });
    },
    fetchStories(context, { id }) {
      const FB = window.FB;

      const fields = [
        'id',
        'media_url',
        'thumbnail_url',
        'caption',
        'media_type',
        'media_product_type',
        'permalink',
        'comments_count',
        'like_count',
        'timestamp'
      ].join(',');

      const params = { fields, limit: 50 };

      FB.api(`/${id}/stories`, 'get', params, (res) => {
        if (res.error) {
          this.error = res.error;
        }
        context.commit('stories', res);
      });
    }
  },
  modules: {}
});
