<template>
  <nav>
    <el-card style="margin-bottom: 10px">
      <account-summary></account-summary>
      <el-menu :router="true">
        <el-menu-item
          index="0"
          :route="{
            name: 'page',
            params: { id: $route.params.id, pid: $route.params.pid }
          }"
        >
          アカウントとメディア
        </el-menu-item>
        <el-menu-item
          index="2"
          :route="{
            name: 'stories',
            params: { id: $route.params.id, pid: $route.params.pid }
          }"
        >
          ストーリーズ （工事中）
        </el-menu-item>
        <el-menu-item
          index="1"
          :route="{
            name: 'audience',
            params: { id: $route.params.id, pid: $route.params.pid }
          }"
        >
          オーディエンス
        </el-menu-item>
      </el-menu>
    </el-card>
  </nav>
</template>

<script>
import AccountSummary from '../components/AccountSummary';

export default {
  components: {
    AccountSummary
  }
};
</script>

<style></style>
