<script>
import { Doughnut } from 'vue-chartjs';

export default {
  extends: Doughnut,
  props: ['metric'],
  mounted() {
    this.renderChart(
      {
        labels: this.labels,

        datasets: [
          {
            backgroundColor: [
              'rgba(43,162,58,1)',
              'rgba(43,162,58,0.9)',
              'rgba(43,162,58,0.8)',
              'rgba(43,162,58,0.7)',
              'rgba(43,162,58,0.6)',
              'rgba(43,162,58,0.5)',
              'rgba(43,162,58,0.4)',
              'rgba(43,162,58,0.3)',
              'rgba(43,162,58,0.2)'
            ],
            data: this.values
          }
        ]
      },
      {
        responsive: true,
        legend: {
          display: false
        }
      }
    );
  },
  computed: {
    labels() {
      return this.metric.map((i) => {
        return i.key;
      });
    },
    values() {
      return this.metric.map((i) => {
        return i.value;
      });
    },
    colors() {
      const base = 'rgba(43,162,58, 1)';
      return base;
    }
  }
};
</script>
