const CountryCodeFilter = require('./CountryCodeFilter');
const CityTransrateFilter = require('./CityTranslateFilter');

module.exports = function audienceDataFilter(audience) {
  const promises = audience.map(async (a) => {
    if (a.name === 'audience_country') {
      const filter = new CountryCodeFilter(a);
      const filtered = await filter.filter();
      return filtered;
    }
    if (a.name === 'audience_city') {
      const filter = new CityTransrateFilter(a);
      const filtered = await filter.filter();
      return filtered;
    }
    return a;
  });
  return Promise.all(promises);
};
